import { useMemo } from 'react';
import { Select } from '@mantine/core';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';

const LeagueTypeSelect = () => {
  const {
    results: { leagues },
    leagueType,
    handleLeagueType,
  } = useProbabilityProvider();

  const leagueLevels = useMemo(
    () =>
      [...new Set(leagues.map((league) => league.league.leagueLevel))]
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b))
        .map((leagueLevel) => ({
          value: leagueLevel,
          label: leagueLevel.toUpperCase().replace(/-/g, ' '),
        })),
    [leagues],
  );

  return (
    <Select
      value={leagueType}
      label="League Level"
      placeholder="Pick value"
      data={leagueLevels}
      clearable
      searchable
      onChange={(value) => handleLeagueType(value)}
      nothingFoundMessage="No league levels found"
    />
  );
};

export default LeagueTypeSelect;
