import { Anchor, Tooltip } from '@mantine/core';

const AnchorWithTooltip = ({ children, label, href, ...props }) => (
  <Tooltip label={label} position="top" color="gray" {...props}>
    <Anchor href={href} target="_blank">
      {children}
    </Anchor>
  </Tooltip>
);

export default AnchorWithTooltip;
