import { useState, createContext, useContext, useReducer, useCallback } from 'react';

const ProbabilityContext = createContext();

export const useProbabilityProvider = () => useContext(ProbabilityContext);

const initialState = {
  nameSearch: null,
  leagueType: null,
  selectedLeague: null,
  onlyActive: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setNameSearch':
      return { ...state, nameSearch: action.value };
    case 'setLeagueType':
      return { ...state, leagueType: action.value };
    case 'setLeague':
      return { ...state, selectedLeague: action.value };
    case 'toggleActive':
      return { ...state, onlyActive: !state.onlyActive };
    case 'clearFilters':
      return initialState;
    default:
      throw new Error();
  }
};

export const ProbabilityProvider = ({ children }) => {
  const [results, setResults] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [show, setShow] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [{ nameSearch, leagueType, selectedLeague, onlyActive }, dispatch] = useReducer(reducer, initialState);

  const handleNameSearch = useCallback((name) => {
    setShow(name ? 999 : 10);
    dispatch({ type: 'setNameSearch', value: name });
  }, []);

  const handleLeagueType = useCallback((value) => {
    setShow(10);
    dispatch({ type: 'setLeagueType', value });
  }, []);

  const handleLeague = useCallback((value) => {
    setShow(10);
    dispatch({ type: 'setLeague', value });
  }, []);

  const toggleActive = useCallback(() => {
    setShow(10);
    dispatch({ type: 'toggleActive' });
  }, []);

  const clearFilters = useCallback(() => {
    setShow(10);
    dispatch({ type: 'clearFilters' });
  }, []);

  return (
    <ProbabilityContext.Provider
      value={{
        results,
        loading,
        error,
        show,
        nameSearch,
        leagueType,
        selectedLeague,
        onlyActive,
        searchParams,
        formValues,
        setResults,
        setLoading,
        setError,
        setShow,
        handleNameSearch,
        handleLeagueType,
        handleLeague,
        toggleActive,
        setSearchParams,
        setFormValues,
        clearFilters,
      }}
    >
      {children}
    </ProbabilityContext.Provider>
  );
};
