export const getSeasons = (stats) => {
  const { firstSeason, lastSeason } = stats || {};
  if (!firstSeason || !lastSeason) return '-';

  return `${firstSeason.startYear}-${lastSeason.endYear}`;
};

export const formatStats = (stats, position) => {
  if (position === 'G') {
    return {
      ['GP']: stats?.GP ?? '-',
      ['W']: stats?.W ?? '-',
      ['GAA']: stats?.GAA ?? '-',
      ['SVP']: stats?.SVP ? (stats?.SVP / 100).toFixed(3) : '-',
      ['GA']: stats?.GA ?? '-',
      ['SV']: stats?.SVS ?? '-',
      ['SO']: stats?.SO ?? '-',
      ['W-L-T']: stats?.GP ? `${stats?.W || 0}-${stats?.L || 0}-${stats?.T || 0}` : '-',
    };
  }
  return {
    ['GP']: stats?.GP ?? '-',
    ['PTS']: stats?.PTS ?? '-',
    ['PPG']: stats?.PPG ?? '-',
    ['G']: stats?.G ?? '-',
    ['A']: stats?.A ?? '-',
    ['+/-']: stats?.PM ?? '-',
    ['PIM']: stats?.PIM ?? '-',
  };
};
