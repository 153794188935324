import { useMemo } from 'react';
import { Select } from '@mantine/core';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';

const LeagueSelect = (props) => {
  const {
    results: { leagues },
    selectedLeague,
    handleLeague,
  } = useProbabilityProvider();

  const choices = useMemo(
    () =>
      [...new Set(leagues.map((league) => league.league.leagueName))]
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b)),
    [leagues],
  );

  return (
    <Select
      value={selectedLeague}
      searchable
      clearable
      label="League"
      placeholder="Select a league"
      data={choices}
      onChange={(value) => handleLeague(value)}
      nothingFoundMessage="No leagues found"
      {...props}
    />
  );
};

export default LeagueSelect;
