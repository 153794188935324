import { useMemo, useState } from 'react';
import { Accordion, Avatar, Flex, Group, SegmentedControl, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useProbabilityProvider } from './providers/ProbabilityProvider';
import AnchorWithTooltip from './common/AnchorWithTooltip';
import PlayersTable from './PlayersTable';

const SLUG_TO_LEVEL_RENAME = {
  ushl: 'junior-tier-i',
};

const formatLeagueLevel = (level) => level.toUpperCase().replace(/-/g, ' ');

const getGamesPlayed = (playerStats, selectedStats) =>
  playerStats.reduce((gamesPlayed, { stats }) => {
    const gp = stats?.[selectedStats]?.GP;
    if (!gp) return gamesPlayed;
    gamesPlayed += gp;
    return gamesPlayed;
  }, 0);

const LeagueCard = ({ league }) => {
  const isSmall = useMediaQuery('(max-width: 500px)');
  const { nameSearch, onlyActive } = useProbabilityProvider();
  const [selectedStats, setSelectedStats] = useState('regularStats');
  let {
    league: { slug, leagueName, fullName, logoUrl, imageUrl, eliteprospectsUrlPath, leagueLevel },
    playerStats,
    probabilityPercent,
  } = league;

  if (SLUG_TO_LEVEL_RENAME[slug]) {
    leagueLevel = SLUG_TO_LEVEL_RENAME[slug];
  }

  const filteredPlayerStats = useMemo(
    () =>
      playerStats.filter(
        (playerStat) =>
          (!nameSearch || playerStat.player.name === nameSearch) &&
          (!onlyActive || playerStat.player.status === 'active'),
      ),
    [playerStats, nameSearch, onlyActive],
  );

  const numOfPlayers = filteredPlayerStats?.length;
  if (!league || !numOfPlayers) return null;

  const probability = probabilityPercent?.toFixed(1) + '%';
  const logo = logoUrl || imageUrl;
  const averageGames = getGamesPlayed(filteredPlayerStats, selectedStats) / numOfPlayers;

  return (
    <Accordion.Item value={slug} shadow="lg" style={{ backgroundColor: 'white' }}>
      <Accordion.Control
        icon={
          <Avatar src={logo} size={40}>
            {leagueName.charAt(0)}
          </Avatar>
        }
      >
        <Flex align="center">
          <Stack gap={0} ml={8}>
            <Group gap={8}>
              <AnchorWithTooltip label={fullName} href={`https://www.eliteprospects.com${eliteprospectsUrlPath}`}>
                <Text size="xl">{leagueName}</Text>
              </AnchorWithTooltip>
              {leagueLevel && (
                <Text size="md" c="dimmed">
                  {formatLeagueLevel(leagueLevel)}
                </Text>
              )}
              <Text>{`(${numOfPlayers})`}</Text>
            </Group>
            <Text size="sm">AVG GP: {averageGames.toFixed(0)}</Text>
          </Stack>
          <Text size="xl" ml="auto" mr={12}>
            {probability}
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel styles={{ content: { paddingInline: 0 } }}>
        <SegmentedControl
          m={12}
          color="black"
          fullWidth
          onChange={setSelectedStats}
          data={[
            { label: isSmall ? 'Regular' : 'Regular Season', value: 'regularStats' },
            { label: isSmall ? 'Post' : 'Post Season', value: 'postseasonStats' },
            { label: 'Total', value: 'totalStats' },
          ]}
        />
        <PlayersTable players={filteredPlayerStats} selectedStats={selectedStats} />
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default LeagueCard;
