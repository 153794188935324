import { useEffect, useState } from 'react';
import { Avatar, Box, Flex, Grid, Group, Progress, RingProgress, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import AnchorWithTooltip from './common/AnchorWithTooltip';

const useCounter = (targetValue, initialValue = 0, duration = 1000) => {
  const [currentValue, setCurrentValue] = useState(initialValue);

  useEffect(() => {
    let startValue = initialValue;
    const interval = Math.floor(duration / (targetValue - initialValue));
    const counter = setInterval(() => {
      startValue += 1;
      if (startValue >= targetValue) {
        setCurrentValue(targetValue);
        clearInterval(counter);
      } else {
        setCurrentValue(startValue);
      }
    }, interval);
    return () => {
      clearInterval(counter);
    };
  }, [targetValue, initialValue, duration]);

  return currentValue;
};

const LeagueInfo = ({ league, order = null }) => {
  const { leagueName, fullName, eliteprospectsUrlPath, logoUrl, imageUrl } = league;
  const logo = logoUrl || imageUrl;

  return (
    <Group>
      {order && <Text>{order + 1}</Text>}
      <Avatar src={logo} size="sm">
        {leagueName.charAt(0)}
      </Avatar>
      <AnchorWithTooltip label={fullName} href={`https://www.eliteprospects.com${eliteprospectsUrlPath}`}>
        <Text size="lg" fw={500}>
          {leagueName}
        </Text>
      </AnchorWithTooltip>
    </Group>
  );
};

const LeagueProgress = ({ league, probabilityPercent }) => {
  const isMobile = useMediaQuery('(max-width: 500px)');
  const currentProbability = useCounter(probabilityPercent);

  const probability = currentProbability.toFixed(1);

  if (isMobile) {
    return (
      <Box>
        <LeagueInfo league={league} />
        <Grid align="center" gutter="xs">
          <Grid.Col span="auto">
            <Progress radius="xl" size="xl" value={probability} bg="white" />
          </Grid.Col>
          <Grid.Col span={2} align="center">
            <Text fz="lg" fw={500}>{`${probability}%`}</Text>
          </Grid.Col>
        </Grid>
      </Box>
    );
  }
  return (
    <>
      <RingProgress
        size={160}
        roundCaps
        label={
          <Text fw={700} size="xl" ta="center">
            {`${probability}%`}
          </Text>
        }
        rootColor="white"
        sections={[{ value: probability, color: 'black' }]}
      />
      <Group mt={8} gap={8}>
        <LeagueInfo league={league} />
      </Group>
    </>
  );
};

const TopThreeLeagues = ({ leagues }) => {
  const isMobile = useMediaQuery('(max-width: 500px)');
  const numOfLeagues = leagues?.length;

  if (!numOfLeagues) return null;
  return (
    <Box mb="xl">
      <Title order={2} align="center" mb="md">
        {numOfLeagues >= 3 ? 'Top 3 Leagues' : 'Top Leagues'}
      </Title>
      {isMobile ? (
        <Stack mx="xs">
          {leagues.map(({ league, probabilityPercent }, index) => (
            <LeagueProgress key={index} league={league} probabilityPercent={probabilityPercent} />
          ))}
        </Stack>
      ) : (
        <Flex justify="center" gap={8} pb="md">
          {leagues.map(({ league, probabilityPercent }, index) => (
            <Flex key={index} direction="column" align="center">
              <Text>{index + 1}</Text>
              <LeagueProgress league={league} probabilityPercent={probabilityPercent} />
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default TopThreeLeagues;
