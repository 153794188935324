export const STAT_LABELS = {
  player: ['GP', 'PTS', 'PPG', 'G', 'A', '+/-', 'PIM'],
  goalie: ['GP', 'W', 'GAA', 'SVP', 'GA', 'SV', 'SO', 'W-L-T'],
};

export const PLAYER_STATS = {
  GP: 'GP',
  PTS: 'PTS',
  PPG: 'PPG',
  G: 'G',
  A: 'A',
  '+/-': '+/-',
  PIM: 'PIM',
};

export const GOALIE_STATS = {
  GP: 'GP',
  W: 'W',
  GAA: 'GAA',
  SVP: 'SVP',
  GA: 'GA',
  SV: 'SVS',
  SO: 'SO',
  'W-L-T': 'W-L-T',
};
