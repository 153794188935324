import { useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { Anchor, Text } from '@mantine/core';
import { startCase } from 'lodash';

import { formatStats, getSeasons } from '../utils/utils';
import { GOALIE_STATS, PLAYER_STATS } from '../constants';

const formatPlayersStats = (players, selectedStats) =>
  players.map((player) => ({
    ...player,
    stats: {
      ...player.stats,
      selectedStats: formatStats(player.stats[selectedStats], player.player.position),
    },
  }));

const PlayersTable = ({ players, selectedStats }) => {
  const formattedPlayers = formatPlayersStats(players, selectedStats);
  const isGoalie = players[0]?.player?.position === 'G';
  const statKeys = isGoalie ? GOALIE_STATS : PLAYER_STATS;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'player.name',
        header: 'Name',
        size: 175,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Anchor href={`https://www.eliteprospects.com${row.original.player.eliteprospectsUrlPath}`} target="_blank">
            <Text truncate="end" maw={160}>
              {renderedCellValue}
            </Text>
          </Anchor>
        ),
      },
      {
        accessorKey: 'player.position',
        header: 'Pos.',
        size: 60,
        enableSorting: false,
      },
      {
        accessorKey: 'player.age',
        header: 'Age',
        size: 75,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
      },
      {
        accessorKey: 'player.status',
        header: 'Status',
        size: 100,
        Cell: ({ renderedCellValue }) => startCase(renderedCellValue),
      },
      {
        accessorKey: 'stats',
        header: 'Seasons',
        size: 110,
        enableSorting: false,
        Cell: ({ renderedCellValue }) => getSeasons(renderedCellValue),
      },
      ...Object.keys(statKeys).map((key) => ({
        accessorKey: `stats.selectedStats.${key}`,
        header: statKeys[key],
        size: key === 'W-L-T' ? 90 : 70,
        enableSorting: key !== 'W-L-T',
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = rowA.getValue(columnId);
          const valueB = rowB.getValue(columnId);

          if (valueA === '-') return -1;
          if (valueB === '-') return 1;

          return valueA - valueB;
        },
      })),
    ],
    [statKeys],
  );

  const table = useMantineReactTable({
    data: formattedPlayers,
    columns,
    enableSortingRemoval: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableRowVirtualization: true,
    rowVirtualizerProps: { overscan: 8 },
    mantineTableContainerProps: { style: { maxHeight: 500 } },
    mantineTableHeadCellProps: { style: { paddingInline: 8 } },
    initialState: {
      density: 'xs',
      sorting: [{ id: `stats.selectedStats.${isGoalie ? 'SVP' : 'PPG'}`, desc: true }],
    },
  });

  if (!players?.length)
    return (
      <Text align="center" mt={32}>
        No results found
      </Text>
    );

  return <MantineReactTable table={table} />;
};

export default PlayersTable;
