import { Fragment, useState } from 'react';
import { Accordion, Box, Button, Collapse, Flex, Group, Stack, Switch, Text } from '@mantine/core';
import { IconFilter, IconFilterFilled } from '@tabler/icons-react';

import { useProbabilityProvider } from './providers/ProbabilityProvider';

import { PoweredByEliteProspectsText } from './ProbabilityForm';

import ExportButton from './buttons/ExportButton';
import ShareButton from './buttons/ShareButton';
import LeagueTypeSelect from './inputs/LeagueTypeSelect';
import LeagueSelect from './inputs/LeagueSelect';
import PlayerSearch from './inputs/PlayerSearch';
import TopThreeLeagues from './TopThreeLeagues';
import LeagueCard from './LeagueCard';

const positionMap = {
  F: { singular: 'Forward', plural: 'Forwards' },
  D: { singular: 'Defenseman', plural: 'Defensemen' },
  G: { singular: 'Goalie', plural: 'Goalies' },
};

const getPositionLabel = (positionKey, number) => {
  const position = positionMap[positionKey];
  if (!position) return null;

  return number === 1 ? position.singular : position.plural;
};

const ActionBar = () => {
  const [showFilters, setShowFilters] = useState(false);
  const { nameSearch, leagueType, selectedLeague, onlyActive, toggleActive, clearFilters } = useProbabilityProvider();

  const hasFilters = nameSearch || leagueType || selectedLeague || onlyActive;

  return (
    <>
      <Flex gap="xs" mb="xs" justify="flex-end">
        <Button
          variant="light"
          onClick={() => setShowFilters(!showFilters)}
          leftSection={hasFilters ? <IconFilterFilled size={16} /> : <IconFilter size={16} />}
          style={{ alignSelf: 'flex-end' }}
        >
          Filters
        </Button>
        <ShareButton />
        <ExportButton />
      </Flex>
      <Collapse in={showFilters}>
        <Box
          mb={16}
          p={16}
          style={{
            borderRadius: 'var(--mantine-radius-md)',
            boxShadow: 'var(--mantine-shadow-sm)',
            backgroundColor: 'white',
          }}
        >
          <Group gap="xs" mb="sm">
            <IconFilter size={18} />
            <Text size="lg" fw={500}>
              Filters
            </Text>
          </Group>
          <Stack gap="xs" mb="lg">
            <PlayerSearch />
            <LeagueSelect />
            <LeagueTypeSelect />
            <Switch mt="xs" color="green" label="Active Players Only" checked={onlyActive} onChange={toggleActive} />
          </Stack>
          <Group justify="space-between">
            <Button size="xs" variant="filled" color="red" onClick={clearFilters} disabled={!hasFilters}>
              Clear
            </Button>
            <Button size="xs" onClick={() => setShowFilters(false)}>
              Close
            </Button>
          </Group>
        </Box>
      </Collapse>
    </>
  );
};

const LeaguesAccordion = ({ leagues }) => {
  const { show } = useProbabilityProvider();

  if (!leagues?.length)
    return (
      <Text align="center" mt={32}>
        No results found
      </Text>
    );

  return (
    <Accordion variant="contained" radius="md" style={{ boxShadow: 'var(--mantine-shadow-sm)' }}>
      {leagues.slice(0, show).map((league, index) => (
        <LeagueCard key={index} league={league} />
      ))}
    </Accordion>
  );
};

const SummaryOfSearch = () => {
  const { formValues, results } = useProbabilityProvider();

  const { position, league, team, ppg, svp, age, range } = formValues;
  const { totalPlayersFound, totalPlayersWithStats } = results || {};

  const summaryParts = [
    totalPlayersFound && (
      <span>
        Found <b>{totalPlayersFound}</b>
      </span>
    ),
    position && (
      <span>
        <u>{getPositionLabel(position, totalPlayersFound)}</u> who played
      </span>
    ),
    league && !team && (
      <span>
        in the <u>{league.toUpperCase()}</u>
      </span>
    ),
    team && (
      <span>
        for the <u>{team}</u>
      </span>
    ),
    age && (
      <span>
        at <u>{age} years old</u>
      </span>
    ),
    (ppg || svp) && (
      <span>
        with a{' '}
        <u>
          {ppg || svp} {ppg ? 'PPG' : 'SV%'}
        </u>
      </span>
    ),
    range && (
      <span>
        in the last <u>{range} years</u>
      </span>
    ),
    totalPlayersWithStats && (
      <span>
        <b>{totalPlayersWithStats}</b> of them went to play in the...
      </span>
    ),
  ].filter(Boolean);

  return (
    <Box maw={600} mb="lg" mt={48} ta="center" mx="auto">
      <Text size="lg">
        {summaryParts.map((part, index) => (
          <Fragment key={index}>
            {part}
            {index === summaryParts.length - 1 - (totalPlayersWithStats ? 1 : 0) ? '. ' : ' '}
          </Fragment>
        ))}
      </Text>
    </Box>
  );
};

export default function Results() {
  const { results, show, selectedLeague, leagueType, nameSearch, onlyActive, setShow } = useProbabilityProvider();
  const { leagues = [] } = results || {};

  const sortedLeagues = leagues.sort((a, b) => b.probabilityPercent - a.probabilityPercent);
  const filteredLeagues = sortedLeagues.filter(
    ({ league, playerStats }) =>
      (!selectedLeague || league.leagueName === selectedLeague) &&
      (!leagueType || league.leagueLevel === leagueType) &&
      (!nameSearch || playerStats.some((playerStat) => playerStat.player.name === nameSearch)) &&
      (!onlyActive || playerStats.some((playerStat) => playerStat.player.status === 'active')),
  );

  const topLeagues = sortedLeagues.slice(0, 3);
  const canShowMore = filteredLeagues?.length > show;

  if (!results) return null;
  if (!leagues?.length)
    return (
      <Text align="center" mt={32}>
        No results found
      </Text>
    );

  return (
    <Box mb="xl">
      <SummaryOfSearch />
      <TopThreeLeagues leagues={topLeagues} />
      <ActionBar />
      <LeaguesAccordion leagues={filteredLeagues} />
      {canShowMore && (
        <Button fullWidth mt={24} onClick={() => setShow(show + 10)}>
          Show more
        </Button>
      )}
      <PoweredByEliteProspectsText align="center" size="xs" c="dimmed" mt="xl" />
    </Box>
  );
}
