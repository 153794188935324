import { Button, createTheme, rem } from '@mantine/core';

import classes from './styles/button.module.css';

const theme = createTheme({
  cursorType: 'pointer', // set cursor type to pointer for all interactive elements

  fontFamily: 'Poppins, sans-serif',

  primaryColor: 'dark',
  secondaryColor: 'teal',

  headings: {
    fontFamily: 'Unna, sans-serif',
    sizes: {
      h1: { fontSize: rem(50) },
    },
  },
  components: {
    Button: Button.extend({
      classNames: classes,
      defaultProps: { variant: 'dark' },
    }),
  },
});

export default theme;
