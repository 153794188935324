import { useEffect } from 'react';

const useResizeHeight = () => {
  useEffect(() => {
    if (window.parent === window) {
      return;
    }

    const app = document.querySelector('#probability-tool');
    if (!app) {
      console.error('Element not found: #probability-tool');
      return;
    }

    const updateHeight = () => {
      const style = window.getComputedStyle(app);
      const marginTop = parseInt(style.marginTop, 10);
      const marginBottom = parseInt(style.marginBottom, 10);

      const height = app.clientHeight + marginTop + marginBottom;

      window.parent.postMessage({ height }, 'https://ahadvising.com/');
    };

    updateHeight();
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    // Observe the app element
    resizeObserver.observe(app);

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
};

export default useResizeHeight;
