import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App.jsx';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.3, //  Capture 30% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const fallback = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      color: 'black',
      backgroundColor: 'white',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 'bold',
    }}
  >
    <p>Oops! Something went wrong.</p>
    <button
      style={{
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '18px',
        color: 'white',
        backgroundColor: 'black',
        border: '2px solid black',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={() => window.location.reload()}
    >
      Try Again
    </button>
  </div>
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={fallback}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
