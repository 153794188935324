import { Button } from '@mantine/core';
import { IconTableExport } from '@tabler/icons-react';
import { CSVLink } from 'react-csv';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';

import { formatStats, getSeasons } from '../../utils/utils';
import { STAT_LABELS } from '../../constants';

const STAT_TYPES = {
  regularStats: 'Regular Season',
  postseasonStats: 'Post Season',
  totalStats: 'Total Stats',
};

const MAIN_HEADERS = [
  { label: 'League', key: 'leagueName' },
  { label: 'League Full Name', key: 'leagueFullName' },
  { label: 'Probability %', key: 'probabilityPercent' },
  { label: 'Name', key: 'playerName' },
  { label: 'Position', key: 'playerPosition' },
  { label: 'Age', key: 'playerAge' },
  { label: 'Status', key: 'playerStatus' },
  { label: 'Seasons', key: 'seasons' },
  { label: 'Stat Type', key: 'statType' },
];

const toHeader = (label, key) => ({ label, key });
const getStatsHeaders = (stats) => stats.map((stat) => toHeader(stat, stat));

const parseData = (leagues) => {
  if (!leagues?.length) return;

  return leagues
    .map(({ league, playerStats, probabilityPercent }) => {
      return playerStats.map(({ player, stats }) => {
        return Object.keys(STAT_TYPES).map((statTypeKey) => ({
          leagueName: league.leagueName,
          leagueFullName: league.fullName,
          probabilityPercent: probabilityPercent?.toFixed(1),
          playerName: player.name,
          playerPosition: player.position,
          playerAge: player.age,
          playerStatus: player.status,
          seasons: getSeasons(stats),
          statType: STAT_TYPES[statTypeKey],
          ...formatStats(stats[statTypeKey], player.position),
        }));
      });
    })
    .flat(2);
};

const ExportButton = ({ ...props }) => {
  const { results } = useProbabilityProvider();
  const { leagues } = results || {};

  const isGoalie = leagues?.[0].playerStats?.[0]?.player?.position === 'G';
  const statLabels = isGoalie ? STAT_LABELS.goalie : STAT_LABELS.player;

  const headers = [...MAIN_HEADERS, ...getStatsHeaders(statLabels)];
  const data = parseData(leagues);

  if (!data) return null;
  return (
    <CSVLink
      filename="Probability Rows"
      data={data}
      headers={headers}
      target="_blank"
      style={{ alignSelf: 'flex-end' }}
    >
      <Button variant="light" leftSection={<IconTableExport size={16} />} {...props}>
        Export
      </Button>
    </CSVLink>
  );
};

export default ExportButton;
