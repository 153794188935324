import { Button, CopyButton, Group, Popover, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck, IconCopy, IconShare } from '@tabler/icons-react';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';

const ShareButton = ({ timeout = 1000 }) => {
  const { searchParams } = useProbabilityProvider();
  const [opened, { close, toggle }] = useDisclosure(false);

  const url = 'https://ahadvising.com/probability-tool/?' + searchParams;

  const handleCopy = (copy) => {
    copy();
    setTimeout(() => close(), timeout);
  };

  return (
    <Popover opened={opened} onChange={toggle} width={300} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button variant="light" size="sm" leftSection={<IconShare size={16} />} onClick={toggle}>
          Share
        </Button>
      </Popover.Target>
      <Popover.Dropdown style={{ borderRadius: 'var(--mantine-radius-md)' }}>
        <Group gap="xs" mb="xs">
          <IconShare size={18} />
          <Text size="lg" fw={500}>
            Share Results
          </Text>
        </Group>
        <Text size="sm" mb="sm">
          Copy the url and share to a friend!
        </Text>
        <TextInput defaultValue={url} mb="lg" readOnly />
        <Group justify="space-between">
          <Button variant="light" size="xs" onClick={close}>
            Dismiss
          </Button>
          <CopyButton value={url} timeout={timeout}>
            {({ copied, copy }) => (
              <Button
                size="xs"
                variant="filled"
                color={copied ? 'green' : 'black'}
                leftSection={copied ? <IconCheck size={14} /> : <IconCopy size={14} />}
                onClick={() => handleCopy(copy)}
              >
                {copied ? 'Copied' : 'Copy'}
              </Button>
            )}
          </CopyButton>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ShareButton;
