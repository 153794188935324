import { useMemo } from 'react';
import { Select } from '@mantine/core';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';

const PlayerSearch = () => {
  const {
    results: { leagues },
    nameSearch,
    handleNameSearch,
  } = useProbabilityProvider();

  const players = useMemo(
    () => [...new Set(leagues.flatMap((league) => league.playerStats.flatMap((playerStat) => playerStat.player.name)))],
    [leagues],
  );

  return (
    <Select
      value={nameSearch}
      label="Player Name"
      placeholder="Search by player name"
      data={players}
      onChange={handleNameSearch}
      searchable
      clearable
      limit={10}
      nothingFoundMessage="No players found"
    />
  );
};

export default PlayerSearch;
