import { useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Loader, Select, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { camelCase, startCase } from 'lodash';

import useQuery from '../hooks/useQuery';

const renderLeague = ({ option }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', width: '100%' }}>
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <img src={option?.flagUrl?.small} style={{ width: '20px', height: 'auto' }} />
        <Text size="sm">{option?.label}</Text>
      </div>
      <Text size="xs" opacity={0.5}>
        {startCase(camelCase(option?.leagueLevel || option?.teamClass))}
      </Text>
    </div>
    {(option?.logoUrl || option?.imageUrl) && (
      <Avatar
        src={option?.logoUrl || option?.imageUrl}
        size={28}
        radius="xs"
        styles={{ image: { objectFit: 'cover' } }}
      />
    )}
  </div>
);

const LeagueInput = ({ onChange, ...props }) => {
  const hasUsedLeagueParamRef = useRef(false);
  const [input, setInput] = useState('');
  const [league, setLeague] = useState(null);
  const [debounce] = useDebouncedValue(input?.trim(), 200);
  const { data, loading } = useQuery(`/leaguesByName?name=${debounce}`, debounce?.length >= 2);

  const leagues = useMemo(
    () => (data?.length ? data?.map((league) => ({ ...league, value: league.slug, label: league.name })) : []),
    [data],
  );

  useEffect(() => {
    if (window.parent === window || hasUsedLeagueParamRef.current) {
      return;
    }

    const handleMessage = (event) => {
      if (typeof event.data === 'object' && event.data !== null) {
        if (!event.data?.league) return;
        setInput(event.data?.league.toUpperCase());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (hasUsedLeagueParamRef.current) return;
    const paramLeague = leagues.find((l) => l.name === input);
    if (paramLeague) {
      setLeague(paramLeague);
      hasUsedLeagueParamRef.current = true;
    }
  }, [input, leagues]);

  const handleClear = () => {
    onChange(null);
    setLeague(null);
  };

  const handleChange = (value, option) => {
    setInput(option?.name);
    setLeague(option);
    onChange(value);
  };

  return (
    <Select
      {...props}
      value={league}
      data={leagues}
      label="League"
      placeholder="Select your league"
      comboboxProps={{ shadow: 'xs' }}
      maxDropdownHeight={200}
      searchValue={input}
      onSearchChange={setInput}
      onChange={handleChange}
      onClear={handleClear}
      nothingFoundMessage={input?.length >= 2 ? 'No leagues found' : 'Type at least 2 characters to search'}
      renderOption={renderLeague}
      leftSection={league && <Avatar src={league?.logoUrl || league?.imageUrl} size={20} radius="xs" />}
      rightSection={loading && <Loader size="xs" />}
      searchable
      clearable
      size="lg"
      onBlur={() => league && setInput(league?.label)}
    />
  );
};

export default LeagueInput;
