// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import 'mantine-react-table/styles.css'; //import MRT styles

import { Container, MantineProvider, Title } from '@mantine/core';

import { ProbabilityProvider } from './components/providers/ProbabilityProvider';
import useResizeHeight from './components/hooks/useResizeHeight';
import ProbabilityForm from './components/ProbabilityForm';
import Results from './components/Results';

import theme from './theme';

export default function App() {
  useResizeHeight();

  const padding = window.parent === window ? 'sm' : 0;

  return (
    <div id="probability-tool" style={{ marginBlock: '48px' }}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <ProbabilityProvider>
          <Container size="xs" px={padding}>
            <Title order={1} align="center" mb="md">
              Player Probability Tool
            </Title>
            <ProbabilityForm />
          </Container>
          <Container size="md" px={padding}>
            <Results />
          </Container>
        </ProbabilityProvider>
      </MantineProvider>
    </div>
  );
}
