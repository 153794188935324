import { Avatar, Loader, Select, Text } from '@mantine/core';
import { camelCase, startCase } from 'lodash';

import { useProbabilityProvider } from '../providers/ProbabilityProvider';
import useQuery from '../hooks/useQuery';

const renderTeam = ({ option }) => {
  const teamClass = startCase(camelCase(option.teamClass));
  const teamType = startCase(camelCase(option.teamType));

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', width: '100%' }}>
      <div>
        <Text size="sm">{option.label}</Text>
        <Text size="xs" opacity={0.5}>
          {`${teamClass} - ${teamType}`}
        </Text>
      </div>
      {option?.logoUrl && (
        <Avatar src={option?.logoUrl} size={28} radius="xs" styles={{ image: { objectFit: 'cover' } }} />
      )}
    </div>
  );
};

const TeamInput = ({ value, league, onChange, ...props }) => {
  const { setFormValues } = useProbabilityProvider();
  const { data, loading } = useQuery(`/leagueTeams?league=${league}`, league);

  const teams = league ? data?.map((team) => ({ ...team, value: team.id.toString(), label: team.name })) : [];
  const selectedTeam = teams?.length ? teams.find((team) => team.id == value) : null;

  const handleChange = (team, option) => {
    onChange(team);
    setFormValues((prev) => ({ ...prev, team: option?.name }));
  };

  return (
    <Select
      value={value}
      data={teams}
      onChange={handleChange}
      label="Team"
      placeholder="Select your team"
      description="Optional"
      comboboxProps={{ shadow: 'xs' }}
      maxDropdownHeight={200}
      renderOption={renderTeam}
      leftSection={selectedTeam && <Avatar src={selectedTeam?.logoUrl} size={20} radius="xs" />}
      rightSection={loading && <Loader size="xs" />}
      disabled={!league}
      searchable
      clearable
      size="lg"
      {...props}
    />
  );
};

export default TeamInput;
