import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Anchor,
  Button,
  Collapse,
  Flex,
  Group,
  Image,
  Loader,
  LoadingOverlay,
  NumberInput,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconExclamationCircle } from '@tabler/icons-react';

import { useProbabilityProvider } from './providers/ProbabilityProvider';

import eliteprospectsImgUrl from '../assets/EP_Logo.png';

import LeagueInput from './inputs/LeagueInput';
import TeamInput from './inputs/TeamInput';
import { captureException } from '@sentry/react';

const PPG_DEVIATION = 0.05;
const SVP_DEVIATION = 0.005;

const PPG = 'ppg';
const SVP = 'svp';

const validParams = ['league', 'team', 'age', 'position', 'ppg', 'svp', 'range'];

export const PoweredByEliteProspectsText = (props) => {
  return (
    <Text {...props}>
      <Group gap={6} justify="center">
        Powered by
        <Anchor inherit href="https://www.eliteprospects.com" target="_blank">
          <Image fit="contain" src={eliteprospectsImgUrl} alt="EliteProspects" h={25} w="auto" />
        </Anchor>
      </Group>
    </Text>
  );
};

const fetchResults = async (params, signal) => {
  let searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value) searchParams.append(key, value);
  }

  searchParams = searchParams.toString();

  const res = await fetch(`/probabilities?${searchParams}`, { signal });
  const data = await res.json();
  return data;
};

const validate = (values) => {
  const errors = {};

  if (!values.league) {
    errors.league = 'League is required';
  }

  if (values.league && !values.ppg && !values.svp && (!values.range || values.range > 15)) {
    errors.range = 'Maximum 15 years when no PPG/SVP entered';
  }

  if (values.position !== 'G' && values.ppg && values.ppg < 0) {
    errors.ppg = 'PPG must be greater than 0';
  }
  if (values.position === 'G' && values.svp) {
    if (values.svp < 0) {
      errors.svp = 'SV% must be greater than 0';
    } else if (values.svp > 1) {
      errors.svp = 'SV% cannot be more than 1';
    }
  }

  return errors;
};

const getStatDescription = (value, type) => {
  let description = `Optional - ${type === SVP ? 'Save percentage' : 'Points per game'}`;

  if (!value) return description;

  const deviation = type === SVP ? SVP_DEVIATION : PPG_DEVIATION;
  const decimal = type === SVP ? 3 : 2;

  const rangeLow = (Number(value) - deviation).toFixed(decimal);
  const rangeHigh = (Number(value) + deviation).toFixed(decimal);
  description = `${description} (${rangeLow} - ${rangeHigh})`;

  return description;
};

const getRangeDescription = (range) => {
  const description = 'Optional - How many years of data to consider';
  if (!range) return description;

  const todayYear = new Date().getFullYear();
  const startYear = todayYear - range;
  const endYear = todayYear;

  return `${description} (${startYear} - ${endYear})`;
};

const StatInput = ({ isGoalie = false, setFieldValue, getInputProps }) => {
  useEffect(() => {
    if (isGoalie) {
      setFieldValue(PPG, '');
    } else {
      setFieldValue(SVP, '');
    }
  }, [isGoalie, setFieldValue]);

  if (isGoalie) {
    const svpProps = getInputProps(SVP);
    const svpDescription = getStatDescription(svpProps.value, SVP);

    return (
      <NumberInput
        label="SV%"
        description={svpDescription}
        placeholder="0.875"
        decimalScale={3}
        step={0.005}
        min={0}
        max={1}
        size="lg"
        {...svpProps}
      />
    );
  }

  const ppgProps = getInputProps(PPG);
  const ppgDescription = getStatDescription(ppgProps.value, PPG);

  return (
    <NumberInput
      label="PPG"
      description={ppgDescription}
      placeholder="0.75"
      decimalScale={2}
      step={0.05}
      min={0}
      size="lg"
      {...ppgProps}
    />
  );
};

const ToolDescription = () => (
  <Text size="md" c="dimmed" align="center" mb="xl">
    Determine the probability of advancing to various levels by entering your information below. The probabilities are
    strictly calculated based on historical data alone.
    <PoweredByEliteProspectsText align="center" />
  </Text>
);

const ErrorMessages = () => {
  const { error, setError } = useProbabilityProvider();

  if (!error) return null;

  return (
    <Alert
      mb="xl"
      color="red"
      title="Oops, something went wrong!"
      withCloseButton
      icon={<IconExclamationCircle />}
      onClose={() => setError(null)}
      radius="md"
    >
      {'Please try again later or change the search parameters.'}
    </Alert>
  );
};

export default function ProbabilityForm() {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState(null);
  const formRef = useRef(null);
  const { results, setResults, setError, setFormValues, setSearchParams, clearFilters } = useProbabilityProvider();

  const isIframe = window.parent !== window; // Check if the page is embedded in an iframe

  const { values, setFieldValue, getInputProps, onSubmit, setValues } = useForm({
    initialValues: {
      league: null,
      team: null,
      age: null,
      position: 'F',
      ppg: null,
      svp: null,
      range: null,
    },
    validate,
    onValuesChange: (values) => {
      if (!values.league) {
        setFieldValue('team', null);
      }
    },
  });

  const setParamsRef = useRef();
  setParamsRef.current = setValues;

  useEffect(() => {
    if (!isIframe) return;

    window.parent.postMessage('ready', 'https://ahadvising.com/');

    const messageHandler = (event) => {
      if (typeof event.data === 'object' && event.data !== null) {
        const filteredParams = Object.keys(event.data)
          .filter((key) => validParams.includes(key))
          .reduce((obj, key) => {
            obj[key] = event.data[key];
            return obj;
          }, {});

        if (Object.keys(filteredParams).length) {
          setParamsRef.current(filteredParams);
          setTimeout(
            () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })),
            1000,
          );
        }
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [isIframe]);

  const handleSubmit = (values) => {
    if (loading) return; // Prevent duplicate submissions due to formRef.current.dispatchEvent

    const abortController = new AbortController();
    setController(abortController);

    setError(null);
    setResults(null);
    setLoading(true);
    clearFilters();

    const filteredValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value));
    const search = new URLSearchParams(filteredValues);
    const searchParams = search.toString();

    if (isIframe) {
      window.parent.postMessage({ searchParams }, 'https://ahadvising.com/');
    }

    setFormValues((prev) => ({ ...filteredValues, team: prev?.team }));
    setSearchParams(searchParams);

    fetchResults(values, abortController?.signal)
      .then((data) => {
        setResults(data);
        setOpen(false);
      })
      .catch((err) => {
        if (err.name === 'AbortError') return;

        console.log('err :>> ', err);
        setError(err.message || 'There was an issue fetching the data. Please try again.');
        captureException(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAbort = () => {
    if (controller) {
      controller.abort();
      setController(null); // Reset the controller state
    }
  };

  const isGoalie = values?.position === 'G';

  return (
    <>
      <form ref={formRef} onSubmit={onSubmit(handleSubmit)}>
        <Collapse in={open}>
          <ToolDescription />
          <ErrorMessages />
          <Stack gap={12} mb="md">
            <LeagueInput {...getInputProps('league')} />
            <TeamInput league={values.league} {...getInputProps('team')} />
            <NumberInput
              label="Age"
              placeholder="16"
              min={0}
              size="lg"
              description="Optional"
              {...getInputProps('age')}
            />
            <Radio.Group name="position" label="Position" size="lg" {...getInputProps('position')}>
              <Group mt="xs">
                <Radio value="F" label="Forward" size="md" />
                <Radio value="D" label="Defenseman" size="md" />
                <Radio value="G" label="Goalie" size="md" />
              </Group>
            </Radio.Group>
            <StatInput isGoalie={isGoalie} setFieldValue={setFieldValue} getInputProps={getInputProps} />
            <NumberInput
              label="Date Range"
              description={getRangeDescription(values?.range)}
              placeholder="10"
              min={0}
              size="lg"
              {...getInputProps('range')}
            />
            <Button type="submit" mt="sm" fullWidth>
              Submit
            </Button>
          </Stack>
        </Collapse>
      </form>
      {!!results && (
        <>
          <Button fullWidth variant={!open ? 'dark' : 'light'} onClick={() => setOpen(!open)}>
            {!open ? 'Change Values' : 'Close Form'}
          </Button>
        </>
      )}
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{
          blur: 3,
          gradient: 'radial-gradient(rgba(241, 240, 242, 0.3), rgba(0, 0, 0, 0))',
        }}
        loaderProps={{
          children: (
            <Flex direction="column" align="center" gap={16}>
              <Loader color="black" type="bars" align="center" />
              <Text align="center" fz="xl" mx={24}>
                Please wait while we calculate the probabilities...
              </Text>
              <Button size="xs" onClick={handleAbort}>
                Cancel
              </Button>
            </Flex>
          ),
        }}
      />
    </>
  );
}
